/* Base CSS */


/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/cabin-v26-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/static/fonts/cabin-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/fonts/cabin-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/fonts/cabin-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/static/fonts/cabin-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/fonts/cabin-v26-latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/cabin-v26-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/static/fonts/cabin-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/fonts/cabin-v26-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/fonts/cabin-v26-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/static/fonts/cabin-v26-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/fonts/cabin-v26-latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}



body {
  color: #666666;
  background: #fefefe;
  font-family: "Cabin", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

/* Typography */
h1, h2, h3 {
  color: #111111;
  font-family: "Cabin", sans-serif;
  font-weight: 400;
  margin-top: 0;
}

h4, h5, h6 {
  color: #111111;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-top: 0;
}

h1 {
  font-size: 40px;
  margin-bottom: 24px;
}

h2 {
  font-size: 36px;
  margin-bottom: 22px;
}

h3 {
  font-size: 34px;
  margin-bottom: 20px;
}

h4 {
  font-size: 22px;
  margin-bottom: 18px;
}

h5 {
  font-size: 20px;
  margin-bottom: 14px;
}

h6 {
  font-size: 18px;
  margin-bottom: 12px;
}

a {
  color: #bc9d4b;
  -moz-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  letter-spacing: 0.05em;
  font-weight: 700;
  outline: 0 !important;
}
a:hover, a:active, a:focus {
  color: #927937;
  text-decoration: none;
}

p {
  font-family: "Cabin", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul, ol {
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 20px;
}

.list-featured {
  list-style: none;
  padding: 0;
}

.list-featured li {
  position: relative;
  padding-left: 14px;
  margin-bottom: 7px;
}

.list-featured li:before {
  position: absolute;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 0;
  left: 0;
  top: 7px;
  background-color: #bc9d4b;
}

ol li {
  margin-bottom: 7px;
}

li {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

blockquote {
  border-left: 3px solid #bc9d4b;
}

.blockquote-reverse {
  border-right: 3px solid #bc9d4b;
}

blockquote p {
  font-size: 16px;
  font-weight: 400;
}

cite {
  color: #bc9d4b;
  font-size: 12px;
  opacity: 0.75;
}

strong {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.lead {
  font-size: 18px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-white {
  color: white !important;
}

.text-grey {
  color: #cccccc !important;
}

.text-black {
  color: black !important;
}

.text-default {
  color: #294a6b !important;
}

.text-primary {
  color: #bc9d4b !important;
}

.text-success {
  color: #93bc49 !important;
}

.text-info {
  color: #54bedc !important;
}

.text-warning {
  color: #e2a740 !important;
}

.text-danger {
  color: #de3f3f !important;
}

.bg-default {
  color: white;
  background: #294a6b;
  padding: 3px 4px;
}

.bg-primary {
  color: white;
  background: #bc9d4b;
  padding: 3px 4px;
}

.bg-success {
  color: white;
  background: #93bc49;
  padding: 3px 4px;
}

.bg-info {
  color: white;
  background: #54bedc;
  padding: 3px 4px;
}

.bg-warning {
  color: white;
  background: #e2a740;
  padding: 3px 4px;
}

.bg-danger {
  color: white;
  background: #de3f3f;
  padding: 3px 4px;
}

/* / End Typography */
.row {
  margin-right: 0;
  margin-left: 0;
}

img {
  width: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.btn-pill {
  border: 1px solid transparent;
}

.page-header {
  font-size: 30px;
  font-weight: 700;
  border: none;
  margin-top: 0;
  margin-bottom: 30px;
}

.page-header h2 {
  margin: 0;
}

.page-header.wsub {
  margin-bottom: 0;
}

.page-header.wsub h2 {
  margin-bottom: 0;
}

.sub-title {
  color: #111111;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.sub-title a {
  margin-left: 10px;
}

.sub-title a span {
  margin-left: 5px;
}

.page-header.dark h1 {
  color: white;
}

.page-header.dark h2 {
  color: white;
}

.page-header.dark h3 {
  color: white;
}

.page-header.dark .sub-title {
  color: #f8f8f8;
}

.default-bg-color {
  background-color: #294a6b !important;
}

.primary-bg-color {
  background-color: #bc9d4b !important;
}

.success-bg-color {
  background-color: #93bc49 !important;
}

.info-bg-color {
  background-color: #54bedc !important;
}

.warning-bg-color {
  background-color: #e2a740 !important;
}

.danger-bg-color {
  background-color: #de3f3f !important;
}

.default-color {
  color: #294a6b !important;
}

.primary-color {
  color: #bc9d4b !important;
}

.success-color {
  color: #93bc49 !important;
}

.info-color {
  color: #54bedc !important;
}

.warning-color {
  color: #e2a740 !important;
}

.danger-color {
  color: #de3f3f !important;
}

.small-spacing {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.medium-spacing {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.large-spacing {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.space-top {
  margin-top: 20px !important;
}

.space-top-30 {
  margin-top: 30px !important;
}

.space-top-2x {
  margin-top: 40px !important;
}

.space-bottom {
  margin-bottom: 20px !important;
}

.space-bottom-30 {
  margin-bottom: 30px !important;
}

.space-bottom-2x {
  margin-bottom: 40px !important;
}

.space-left {
  padding-left: 15px !important;
}

.space-left-2x {
  padding-left: 30px !important;
}

.space-right {
  padding-right: 15px !important;
}

.space-right-2x {
  padding-right: 30px !important;
}

.space-25 {
  margin: 12.5px 0;
  line-height: 0;
}

.space-50 {
  margin: 25px 0;
  line-height: 0;
}

.space-80 {
  margin: 40px 0;
  line-height: 0;
}

.space-100 {
  margin: 50px 0;
  line-height: 0;
}

.space-200 {
  margin: 100px 0;
  line-height: 0;
}

.margin-left {
  margin-left: 15px;
}

.margin-left-2x {
  margin-left: 30px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-2x {
  margin-right: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-border {
  border: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 5px !important;
}

a:focus {
  outline: none !important;
}

.btn:focus {
  outline: none !important;
}

.btn-social:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
  border: 1px solid #bc9d4b !important;
  box-shadow: none !important;
}

.form-group:focus {
  outline: none !important;
  border: 1px solid #bc9d4b !important;
  box-shadow: none !important;
}

select:focus {
  outline: none !important;
  border: 1px solid #bc9d4b !important;
  box-shadow: none !important;
}

textarea {
  max-width: 100%;
  max-height: 100%;
}

textarea:focus {
  outline: none !important;
  border: 1px solid #bc9d4b !important;
  box-shadow: none !important;
}

.fa {
  display: initial !important;
}

.separator-line {
  max-width: 60px;
  height: 2px;
  background: #ebebeb;
}

.separator-line-center {
  max-width: 60px;
  height: 2px;
  text-align: center;
  background: #ebebeb;
  margin: 0 auto;
}

.separator-line-2x {
  max-width: 120px;
  height: 3px;
  background: #ebebeb;
}

.separator-line-center-2x {
  max-width: 120px;
  height: 3px;
  text-align: center;
  background: #ebebeb;
  margin: 0 auto;
}

/* Animation Durations */
.animated {
  -webkit-animation-duration: 1s !important;
  animation-duration: 1s !important;
}

.animated.first {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 0.5s;
}

.animated.second {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1s;
}

.animated.third {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 1.3s;
}

.animated.fourth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1.8s;
}

.animated.fifth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2s;
}

.animated.sixth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2.5s;
}

.animated.seventh {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.2s;
}

.animated.eighth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 3.5s;
}

.animated.nineth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.8s;
}

/* End Animation Durations */
::-moz-selection {
  color: white;
  background: rgba(188, 157, 75, 0.77);
}

::selection {
  color: white;
  background: rgba(188, 157, 75, 0.77);
}

/* Buttons */
.btn {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.05em;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  padding: 10px 18px;
  margin: 5px;
}

.btn span {
  margin-left: 15px;
  margin-right: 0;
}

.btn:hover {
  background: transparent;
}

.btn:focus {
  background: transparent;
}

.btn-lg {
  font-size: 15px;
  padding: 12px 20px;
}

.btn-sm {
  font-size: 12px;
  padding: 8px 14px;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 12px;
}

.btn-rounded {
  border-radius: 5px !important;
}

.btn-pill {
  border-radius: 50px !important;
}

.btn-circle {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  padding: 3px !Important;
}

.btn-square {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  padding: 3px !Important;
}

.btn-link {
  color: #bc9d4b;
}

.btn-link:hover {
  color: #927937;
  text-decoration: none;
}

.btn-link:focus {
  color: #927937;
  text-decoration: none;
}

a.btn {
  white-space: nowrap;
}

/* Default Button */
.btn-default, .btn-default:active, .btn-default:focus {
  color: #294a6b;
  border: 2px solid #294a6b;
  background: transparent;
}

.btn-default:hover {
  color: white;
  background: #294a6b;
  border: 2px solid #294a6b;
}

.btn-default-filled, .btn-default-filled:active, .btn-default-filled:focus {
  color: white;
  background: #294a6b;
  border: 2px solid #294a6b;
}

.btn-default-filled:hover {
  color: white;
  background: #1a5d18;
  border: 2px solid #1a5d18;
}

/* / End Default Button */
/* Primary Button */
.btn-primary, .btn-primary:active, .btn-primary:focus {
  color: #bc9d4b;
  border: 2px solid #bc9d4b;
  background: transparent;
}

.btn-primary:hover {
  color: white;
  background: #bc9d4b;
  border: 2px solid #bc9d4b;
}

.btn-primary-filled, .btn-primary-filled:active, .btn-primary-filled:focus {
  color: white;
  background: #bc9d4b;
  border: 2px solid #bc9d4b;
}

.btn-primary-filled:hover {
  color: white;
  background: #927937;
  border: 2px solid #927937;
}

/* / End Primary Button */
/* Success Button */
.btn-success, .btn-success:active, .btn-success:focus {
  color: #93bc49;
  border: 2px solid #93bc49;
  background: transparent;
}

.btn-success:hover {
  color: white;
  background: #93bc49;
  border: 2px solid #93bc49;
}

.btn-success-filled, .btn-success-filled:active, .btn-success-filled:focus {
  color: white;
  background: #93bc49;
  border: 2px solid #93bc49;
}

.btn-success-filled:hover {
  color: white;
  background: #7ea03f;
  border: 2px solid #7ea03f;
}

/* / End Success Button */
/* Info Button */
.btn-info, .btn-info:active, .btn-info:focus {
  color: #54bedc;
  border: 2px solid #54bedc;
  background: transparent;
}

.btn-info:hover {
  color: white;
  background: #54bedc;
  border: 2px solid #54bedc;
}

.btn-info-filled, .btn-info-filled:active, .btn-info-filled:focus {
  color: white;
  background: #54bedc;
  border: 2px solid #54bedc;
}

.btn-info-filled:hover {
  color: white;
  background: #3a9fbc;
  border: 2px solid #3a9fbc;
}

/* / End Info Button */
/* Warning Button */
.btn-warning, .btn-warning:active, .btn-warning:focus {
  color: #e2a740;
  border: 2px solid #e2a740;
  background: transparent;
}

.btn-warning:hover {
  color: white;
  background: #e2a740;
  border: 2px solid #e2a740;
}

.btn-warning-filled, .btn-warning-filled:active, .btn-warning-filled:focus {
  color: white;
  background: #e2a740;
  border: 2px solid #e2a740;
}

.btn-warning-filled:hover {
  color: white;
  background: #cb9538;
  border: 2px solid #cb9538;
}

/* / End Warning Button */
/* Danger Button */
.btn-danger, .btn-danger:active, .btn-danger:focus {
  color: #de3f3f;
  border: 2px solid #de3f3f;
  background: transparent;
}

.btn-danger:hover {
  color: white;
  background: #de3f3f;
  border: 2px solid #de3f3f;
}

.btn-danger-filled, .btn-danger-filled:active, .btn-danger-filled:focus {
  color: white;
  background: #de3f3f;
  border: 2px solid #de3f3f;
}

.btn-danger-filled:hover {
  color: white;
  background: #bf3535;
  border: 2px solid #bf3535;
}

/* / End Danger Button */
/* Button Groups */
.btn-group-xl > .btn {
  font-size: 20px;
  padding: 9px 10px;
  border-radius: 5px;
}

.btn-group-lg > .btn {
  font-size: 15px;
  padding: 10px 12px;
  border-radius: 5px;
}

.btn-group > .btn {
  border-radius: 5px;
}

.btn-group-sm > .btn {
  font-size: 12px;
  padding: 5px 4px;
  border-radius: 5px;
}

.btn-group-xs > .btn {
  font-size: 10px;
  padding: 3px;
  border-radius: 5px;
}

/* / End Button Groups */
/* Dropdown Buttons */
.dropdown-buttons {
  display: inline-block;
  position: relative;
}

.dropdown-buttons .btn-group > .btn {
  padding-left: 17px;
}

.dropdown-buttons .dropdown-icon {
  margin: 0 2px 0 10px;
}

.dropdown-buttons .btn {
  border-radius: 0;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: white;
  background-color: #294a6b;
  border-color: #294a6b;
}

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  color: white;
  background-color: #294a6b;
  border-color: #294a6b;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  color: white;
  background-color: #bc9d4b;
  border-color: #bc9d4b;
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #bc9d4b;
  border-color: #bc9d4b;
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  color: white;
  background-color: #93bc49;
  border-color: #93bc49;
}

.btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
  color: white;
  background-color: #93bc49;
  border-color: #93bc49;
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  color: white;
  background-color: #54bedc;
  border-color: #54bedc;
}

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  color: white;
  background-color: #54bedc;
  border-color: #54bedc;
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  color: white;
  background-color: #e2a740;
  border-color: #e2a740;
}

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  color: white;
  background-color: #e2a740;
  border-color: #e2a740;
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  color: white;
  background-color: #de3f3f;
  border-color: #de3f3f;
}

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  color: white;
  background-color: #de3f3f;
  border-color: #de3f3f;
}

/* / End Dropdown Buttons */
/* / End Buttons */
/* Tabs & Accordions */
/* Tabs */
.nav-tabs {
  border-bottom: 3px solid #ebebeb;
}

.nav-tabs > li {
  float: none;
  margin-bottom: -3px;
  display: inline-block;
}

.nav-tabs > li > a {
  position: relative;
  z-index: 0;
  display: block;
  color: #666666;
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0;
  padding: 20px;
  line-height: 1.5;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}

.nav-tabs > li > a:focus {
  background: transparent;
  border: 0 !important;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #bc9d4b;
  background: none;
  border: none;
  border-bottom: 3px solid #bc9d4b !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.nav > li > a:hover {
  background: none;
  border: none;
}

.nav-tabs > li {
  float: none;
  margin-bottom: -3px;
  display: inline-block;
}

.nav-pills.text-right > li {
  float: none;
  display: inline-block;
}

.nav.nav-pills a:hover {
  color: white;
  background: #927937;
}

.tab-content {
  padding-top: 30px;
}

/* / End Tabs */
/* Accordions */
.panel-group {
  margin-bottom: 10px;
}

.panel-group .panel {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}

.panel-group .panel-heading {
  padding: 10px 0;
  border: none;
  border-radius: 0;
}

.panel-group .panel-title {
  font-size: 14px;
  font-weight: 400;
}

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel-group .panel-heading > a:after {
  display: inline-block;
  vertical-align: middle;
  font-family: "FontAwesome";
  font-size: 10px;
  content: "\f068";
  margin-top: -2px;
  margin-left: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.panel-group .panel-heading .panel-title.collapsed:after {
  content: "\f067";
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-group .panel-body {
  padding: 15px 0;
}

.panel-group.tabbed .panel-heading {
  background: #ebebeb;
  padding: 10px 15px;
}

.panel-group.tabbed a {
  color: #666666;
}

.panel-group.tabbed .panel-heading > a:after {
  float: right;
}

.panel-group.tabbed .panel-heading .panel-title.collapsed:after {
  float: right;
}

/* / End Accordions */
/* / End Tabs & Accordions */
/* Labels Badges & Alerts */
/* Labels */
.label {
  font-weight: 400;
  padding: .2em .6em;
  line-height: 2.5em;
  border-radius: 0;
}

.label-pill {
  border-radius: 5em;
}

.label-default {
  color: #294a6b;
  background-color: transparent;
  border: 2px solid #294a6b;
}

.label-default-filled {
  color: white;
  background-color: #294a6b;
  border: 2px solid #294a6b;
}

.label-primary {
  color: #bc9d4b;
  background-color: transparent;
  border: 2px solid #bc9d4b;
}

.label-primary-filled {
  color: white;
  background-color: #bc9d4b;
  border: 2px solid #bc9d4b;
}

.label-success {
  color: #93bc49;
  background-color: transparent;
  border: 2px solid #93bc49;
}

.label-success-filled {
  color: white;
  background-color: #93bc49;
  border: 2px solid #93bc49;
}

.label-info {
  color: #54bedc;
  background-color: transparent;
  border: 2px solid #54bedc;
}

.label-info-filled {
  color: white;
  background-color: #54bedc;
  border: 2px solid #54bedc;
}

.label-warning {
  color: #e2a740;
  background-color: transparent;
  border: 2px solid #e2a740;
}

.label-warning-filled {
  color: white;
  background-color: #e2a740;
  border: 2px solid #e2a740;
}

.label-danger {
  color: #de3f3f;
  background-color: transparent;
  border: 2px solid #de3f3f;
}

.label-danger-filled {
  color: white;
  background-color: #de3f3f;
  border: 2px solid #de3f3f;
}

/* / End Labels */
/* Badges */
.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: white;
  background-color: #bc9d4b;
}

.nav-pills > li > a:hover {
  color: white;
  background-color: #cccccc;
}

.nav-pills > li > a {
  font-size: 12px;
  border-radius: 0;
  padding: 8px 14px;
}

.badge {
  color: white;
  background-color: #bc9d4b;
  font-size: 10px;
  font-weight: 400;
  border-radius: 0;
  padding: 6px 8px;
}

.active .badge {
  color: #bc9d4b !important;
}

/* / End Badges */
/* Alerts */
.alert {
  border: 2px solid transparent;
  border-radius: 0;
}

.alert-success {
  color: white;
  background-color: #93bc49;
  border-color: #7ea03f;
}

.alert-success .close {
  color: #7ea03f;
}

.alert-info {
  color: white;
  background-color: #54bedc;
  border-color: #3a9fbc;
}

.alert-info .close {
  color: #3a9fbc;
}

.alert-warning {
  color: white;
  background-color: #e2a740;
  border-color: #cb9538;
}

.alert-warning .close {
  color: #cb9538;
}

.alert-danger {
  color: white;
  background-color: #de3f3f;
  border-color: #bf3535;
}

.alert-danger .close {
  color: #bf3535;
}

.alert-dismissible .close {
  font-size: 16px;
  top: -14px;
  right: -31px;
  text-shadow: none;
  opacity: 1;
}

.alert-dismissible .close:hover {
  opacity: 0.8;
}

.alert-sm {
  font-size: 14px;
  padding: 5px;
}

.alert i {
  margin: 0 10px 0 5px;
}

.alert-sm.alert-dismissible .close {
  top: -5px;
  right: -2px;
}

/* / End Alerts */
/* / End Labels Badges & Alerts */
/* Listgroups & Panels */
/* Listgroups */
.list-group-item {
  color: #666666;
  background-color: white;
  font-size: 14px;
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
}

.list-group-item p {
  font-size: 14px;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  color: white;
  background-color: #bc9d4b;
  border-color: #bc9d4b;
}

a.list-group-item:hover {
  color: #bc9d4b;
}

a.list-group-item, button.list-group-item {
  color: #666666;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
  color: white;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #111111;
}

/* / End Listgroups */
/* Panels */
.panel-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.panel-default {
  border-color: #1a5d18;
}

.panel-default > .panel-heading {
  color: white;
  background-color: #294a6b;
  border-color: #1a5d18;
}

.panel-success {
  border-color: #7ea03f;
}

.panel-success > .panel-heading {
  color: white;
  background-color: #93bc49;
  border-color: #7ea03f;
}

.panel-primary {
  border-color: #927937;
}

.panel-primary > .panel-heading {
  color: white;
  background-color: #bc9d4b;
  border-color: #927937;
}

.panel-info {
  border-color: #3a9fbc;
}

.panel-info > .panel-heading {
  color: white;
  background-color: #54bedc;
  border-color: #3a9fbc;
}

.panel-warning {
  border-color: #cb9538;
}

.panel-warning > .panel-heading {
  color: white;
  background-color: #e2a740;
  border-color: #cb9538;
}

.panel-danger {
  border-color: #bf3535;
}

.panel-danger > .panel-heading {
  color: white;
  background-color: #de3f3f;
  border-color: #bf3535;
}

/* / End Panels */
/* Well */
.well {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ebebeb;
  border: 1px solid #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.rounded-well {
  border-radius: 5px;
}

/* / End Well */
/* Jumbotron */
.jumbotron {
  background: #ebebeb;
  border: 1px solid #cccccc;
  border-radius: 0 !important;
}

.rounded-jumbotron {
  border-radius: 5px !important;
}

.jumbotron h1 {
  color: #bc9d4b;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
}

.jumbotron p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 25px;
}

/* / End Jumbotron */
/* / End Listgroups & Panels */
/* Progress Bars */
.progress {
  height: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #ebebeb;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  font-size: 12px;
  line-height: 20px;
  color: white;
  background-color: #bc9d4b;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
}

.progress-bar-default {
  background-color: #294a6b;
}

.progress-bar-primary {
  background-color: #bc9d4b;
}

.progress-bar-success {
  background-color: #93bc49;
}

.progress-bar-info {
  background-color: #54bedc;
}

.progress-bar-warning {
  background-color: #e2a740;
}

.progress-bar-danger {
  background-color: #de3f3f;
}

.progress-label .sr-only {
  display: none;
}

.progress-bar span {
  border-radius: 15px;
  display: inline-block;
  height: 24px;
  letter-spacing: 0;
  line-height: 23px;
  min-width: 24px;
  padding: 0 3px;
  position: absolute;
  right: -5px;
  text-align: center;
  bottom: 0;
}

/* / End Progress Bars */
/* Tables */
table {
  width: 100%;
  max-width: 100%;
}

table > caption + thead > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > th, table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #cccccc;
}

table > thead > tr > th, table > thead > tr > td {
  border-bottom-width: 2px !important;
}

table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tr > td {
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border-bottom: 1px solid #ebebeb;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f8f8f8 !important;
}

/* / End Tables */
/* End Base CSS */
/* Headers */
/* Navigations */
.navbar {
  border: 0;
  border-radius: 0 !important;
  margin-bottom: 0;
}

.navbar-fixed-top {
  border-radius: 0;
}

.navbar-fixed {
  position: absolute;
  top: 0;
  width: 100%;
}

.navbar-nav {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  max-width: 100%;
}

.navbar-nav > li > a {
  line-height: 80px;
  padding: 0 0 0 30px !important;
}

.container > .navbar-header {
  margin-right: 0;
  margin-left: 0;
}

.navbar > .container-fluid .navbar-brand {
  margin-left: 0;
}

.navbar-brand {
  color: #cccccc !important;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  height: auto;
  padding: 20px 15px;
}

.navbar-brand span {
  color: #bc9d4b;
}

.navbar-toggle {
  margin: 0;
  border: 0;
  padding: 25.5px 5px;
}

.navbar-collapse {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
}

.navbar-brand > img {
  width: auto;
  max-height: 40px;
}

.navbar-toggle .icon-bar {
  background: #bc9d4b;
  width: 25px;
  height: 3px;
  margin: 5px 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.dropdown-menu {
  z-index: 9999;
  border-radius: 0 !important;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  color: #bc9d4b;
  background-color: transparent;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 10px;
  margin-top: 5px;
  margin-left: -35px;
  background-color: white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.dropdown-menu > li > a {
  color: #111111;
  padding: 7px 20px;
}

.dropdown-menu > li > a:hover {
  color: #927937;
  background: transparent;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 3px 0;
  background-color: #ebebeb;
}

.dropdown .dropdown-icon {
  color: #cccccc;
  font-size: 7px;
  line-height: 16px;
  font-family: FontAwesome;
  margin-left: 5px;
}

.dropdown .dropdown-icon:before {
  content: "\f067";
}

.dropdown.open .dropdown-icon:before {
  content: "\f068";
}

/* Default Navbar */
.navbar-default {
  background-color: transparent;
  border: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35) !important;
  z-index: 9999;
}

.page-nav {
  background-color: #1a5d18;
  border-bottom: 1px solid rgba(220, 220, 220, 0.35) !important;
}

.navbar-default .navbar-nav > li > a {
  color: #111111;
  font-size: 13px;
  font-weight: 700;
}

.navbar-default.dark-bg .navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.77);
}

.navbar-default .navbar-nav > li > a:hover {
  color: #bc9d4b;
}

.navbar-default .navbar-nav > li > a:focus {
  color: #bc9d4b !important;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
  color: #bc9d4b;
  background-color: transparent;
}

.navbar-default.dark-bg .navbar-nav > .active > a, .navbar-default.dark-bg .navbar-nav > .active > a:focus, .navbar-default.dark-bg .navbar-nav > .active > a:hover {
  color: #bc9d4b;
  background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
  color: #bc9d4b;
  background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #111111;
}

.navbar-default.dark-bg .navbar-toggle .icon-bar {
  background-color: #ebebeb;
}

.navbar-default.dark-bg.top-nav-collapse .navbar-toggle .icon-bar {
  background-color: #bc9d4b;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
  background-color: transparent !important;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.navbar-default.page-nav .navbar-toggle:hover, .navbar-default.page-nav .navbar-toggle:focus {
  background-color: #1a5d18;
}

.navbar-default .dropdown-menu > li > a {
  font-size: 13px;
  font-weight: 700;
}

.top-nav-collapse {
  background-color: white;
  border-bottom: 1px solid rgba(220, 220, 220, 0.35) !important;
}

.collapsed-logo {
  display: none !important;
}

.top-nav-collapse .collapsed-logo {
  display: block !important;
}

.top-nav-collapse .inline-logo {
  display: none !important;
}

.navbar-default.top-nav-collapse.dark-bg .navbar-nav > li > a {
  color: #111111;
}

.navbar-default.top-nav-collapse .navbar-nav > li > a:hover {
  color: #bc9d4b;
}

.navbar-default.top-nav-collapse .navbar-nav > li > a:focus {
  color: #bc9d4b !important;
}

.navbar-default.dark-bg.top-nav-collapse .navbar-nav > .active > a, .navbar-default.dark-bg.top-nav-collapse .navbar-nav > .active > a:focus, .navbar-default.dark-bg.top-nav-collapse .navbar-nav > .active > a:hover {
  color: #bc9d4b;
  background-color: transparent;
}

/* End Default Navbar */
/* End Navigations */
/* Slider */
#slider.has-top-menu .slider-content {
  padding-top: 0px;
}

#slider .carousel-control.left, #slider .carousel-control.right {
  background-image: none !important;
}

#slider .carousel-caption {
  text-align: center;
  height: auto;
  text-shadow: none !important;
  top: 0;
  bottom: 0;
  padding: 0;
}

.carousel-control {
  font-size: 32px;
  text-shadow: none;
  background-color: transparent;
}

#slider.dark .carousel-control {
  color: #ebebeb;
}

.carousel-control .fa-angle-left {
  position: absolute;
  top: 45%;
  left: 15%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  float: left;
}

.carousel-control .fa-angle-right {
  position: absolute;
  top: 45%;
  right: 15%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

#slider .slider-content {
  padding: 50px 0;
}
/*
#slider img {
  max-width: 1500px;
}*/

.slide-title {
  font-size: 40px;
}

.dark-slider .slide-title {
  color: white;
}

.dark-slider .slide-text {
  color: white !important;
}

.slide-content .slide-title span {
  margin-bottom: 15px;
}

.slider-content .slide-text {
  margin-top: 15px;
}

.slider-content .btn {
  margin: 15px;
}

#slider .item {
  min-height: 750px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #1a5d18;
}

#slider.dark .item, #slider .item.dark {
  background-color: #222222;
}

#slider .slide1 {
  background-image: url("../images/slide1.jpg");
}

#slider .slide2 {
  background-image: url("../images/slide2.jpg");
}

#slider .slide3 {
  background-image: url("../images/slide3.jpg");
}

#slider .slide-text {
  color: #111111;
  line-height: 2;
}

#slider p.caption {
  padding: 6px;
  color: #111111;
}

#slider .carousel-control {
  color: #111111;
  opacity: 0.7;
  margin-top: 70px;
}

#slider.dark-slider .carousel-control {
  color: white;
}

.carousel-control .fa-angle-right {
  padding: 10px;
  border: 2px solid white;
  border-radius: 50%;
  width: 56px;
  opacity: 0.3;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.carousel-control:hover .fa-angle-right {
  opacity: 0.7;
}

.carousel-control .fa-angle-left {
  padding: 10px;
  border: 2px solid white;
  border-radius: 50%;
  width: 56px;
  opacity: 0.3;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.carousel-control:hover .fa-angle-left {
  opacity: 0.7;
}

/* End Slider */
/* Image Header */
#image-header {
  padding-top: 230px;
  padding-bottom: 170px;
}

#image-header.ff {
  background: url("../images/ff-header.jpg") no-repeat center;
  background-size: cover;
  background-color: #1a5d18;
}

#image-header.bar {
  background: url("../images/bar-header.jpg") no-repeat center;
  background-size: cover;
  background-color: #1a5d18;
}

#image-header.bar2 {
  background: url("../images/bar-header2.jpg") no-repeat center;
  background-size: cover;
  background-color: #1a5d18;
}

#image-header img {
  max-width: 220px;
}

.header-content {
  text-align: center;
}

.header-content h2 {
  color: white;
  font-size: 40px;
}

.header-content p {
  color: #ebebeb;
  line-height: 2;
  margin-top: 15px;
}

/* End Image Header */
/* End Headers */
/* Content */
section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.parallax-background {
  background-attachment: fixed !important;
}

/* Mini Slider */
#mini-slider .carousel-control.left, #mini-slider .carousel-control.right {
  background-image: none !important;
}

#mini-slider .carousel-caption {
  text-align: center;
  height: auto;
  text-shadow: none !important;
  top: 0;
  bottom: 0;
  padding: 0;
}

#mini-slider .carousel-control {
  font-size: 26px;
  text-shadow: none;
  background-color: transparent;
}

#mini-slider.dark .carousel-control {
  color: #ebebeb;
}

#mini-slider .carousel-control .fa-angle-left {
  position: absolute;
  top: 46%;
  left: 25%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  float: left;
}

#mini-slider .carousel-control .fa-angle-right {
  position: absolute;
  top: 46%;
  right: 25%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

#mini-slider .carousel-control {
  color: rgba(255, 255, 255, 0.77);
  opacity: 0.7;
}

#mini-slider .carousel-control .fa-angle-right {
  padding: 5px;
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  opacity: 0.3;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

#mini-slider .carousel-control:hover .fa-angle-right {
  opacity: 0.7;
}

#mini-slider .carousel-control .fa-angle-left {
  padding: 5px;
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  opacity: 0.3;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

#mini-slider .carousel-control:hover .fa-angle-left {
  opacity: 0.7;
}

/* End Mini Slider */
/* About Section */
#opening {
  background: url("../images/opening.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.opening-box {
  background: rgba(255, 255, 255, 0.35);
  text-align: center;
  width: 68%;
  float: none;
  margin: 0 auto;
  padding: 30px 0;
}

.opening-hours {
  color: white;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

.opening-text {
  color: white;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}

.opening-left {
  border-right: 2px solid rgba(255, 255, 255, 0.77);
}

/* End About Section */
/* Menu Section */
.menu-img {
  max-width: 200px;
  opacity: 0.9;
  margin-bottom: 80px;
}

.food-title {
  margin-top: 30px;
}

.food-text {
  font-size: 13px;
}

.food-img {
  max-width: 600px;
  margin-bottom: 80px;
}

#main-menu {
  padding-top: 0;
}

#desserts-menu {
  padding-top: 0;
}

#coffee-menu {
  padding-top: 0;
}

#coffee-menu {
  padding-top: 0;
}

#drinks-menu {
  padding-top: 0;
}

/* End Menu Section */
/* Gallery Section */
#gallery {
  background-color: #1a5d18;
}

#gallery.full-gallery {
  padding-bottom: 0;
}

#gallery .page-header {
  margin-bottom: 0;
}

.gallery-item {
  padding: 0;
}

.mfp-arrow-right:before {
  border-left: none !important;
}

.mfp-arrow-right:after {
  border-left: 16px solid white !important;
}

.mfp-arrow-left:before {
  border-right: none !important;
}

.mfp-arrow-left:after {
  border-right: 16px solid white !important;
}

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(188, 157, 75, 0.77);
  -webkit-transition: all .2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all .5s linear;
  transition: all .2s linear;
}

.hovereffect-title i {
  color: #bc9d4b;
  font-size: 18px;
  background: rgba(255, 255, 255, 0.77);
  border: 3px solid transparent;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  line-height: 23px;
  text-align: center;
  position: absolute;
  top: 42%;
  right: 44%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px 0;
}

.fa-plus:before {
  margin-left: 2px;
}

.hovereffect:hover img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-container {
  background: #1a5d18 !important;
}

.mfp-wrap {
  z-index: 999999 !important;
}

/* End Gallery Section */
/* Reservations Section */
.reservations-img {
  max-width: 200px;
  opacity: 0.9;
  margin-bottom: 80px;
}

.order-img {
  max-width: 200px;
  opacity: 0.9;
  margin-top: -80px;
  margin-bottom: 80px;
}

#reservation-form {
  max-width: 70%;
  margin: 0 auto;
}

.reservation-left-area {
  padding-right: 7.5px;
}

.reservation-right-area {
  padding-left: 7.5px;
}

/* Forms Styles */
.form-control {
  color: #999;
  background: #f8f8f8;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #ebebeb;
  border-radius: 0;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
}

.has-error .form-control {
  color: #de3f3f !important;
  border: 1px solid #de3f3f !important;
}

.help-block.with-errors li {
  color: #de3f3f;
  font-size: 13px;
  margin-top: -30px;
  margin-left: 10px;
  margin-bottom: 0;
}

#msgSubmit.h3 {
  font-size: 14px;
  margin-top: 5px;
}

/* End Forms Style */
/* End Reservations Section */
/* Testimonials Slider */
#testimonials {
  background: url("../images/testimonials-bg.jpg") no-repeat center;
  background-size: cover;
  background-color: #1a5d18;
}

#testimonials .testimonial {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

#testimonials .testimonial i {
  color: #bc9d4b;
  font-size: 38px;
  margin-bottom: 40px;
}

.testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-top: -40px;
  margin-bottom: 40px;
}

#testimonials .testimonial h3 {
  color: #ebebeb;
  font-size: 30px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  max-width: 900px;
}

.customer-name {
  color: #bc9d4b;
  font-size: 16px;
  font-weight: 400;
}

#testimonials .carousel-indicators {
  margin-bottom: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  background-color: white;
  margin: 3px;
  opacity: 1;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  margin: 3px;
  background-color: white;
  border: 0;
  opacity: 0.5;
}

/* End Testimonials Slider */
/* Contact Section */
#contact {
  background-color: #1a5d18 !important;
  padding-top: 0;
}

/* Maps */
#map {
  width: 100%;
  height: 600px;
  margin-bottom: 80px;
}

/* End Maps */
#contact-form {
  max-width: 70%;
  margin: 0 auto;
}

.contact-left-area {
  padding-right: 7.5px;
}

.contact-right-area {
  padding-left: 7.5px;
}

#contact-form .form-control {
  background: rgba(255, 255, 255, 0.77);
  border-color: #bc9d4b;
}

#contact-form .btn-form-submit {
  width: 100%;
  padding: 14px 24px;
  margin: 0;
}

#contact-form textarea.form-control {
  max-height: 115px;
}

/* End Contact Section */
/* Content */
/* Footers */
footer {
  background: #1a5d18;
  border-top: 1px solid #bc9d4b;
}

.footer-info {
  padding: 25px 0;
}

footer p {
  color: #ebebeb !important;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

footer .social a i {
  color: #bc9d4b;
  font-size: 14px;
  margin-left: 10px;
}

footer p {
  color: #666666;
}

/* End Footers */
/* Scroll to Top */
.scroll-to-top {
  background: #bc9d4b;
  color: white;
  width: 30px;
  height: 30px;
  bottom: 20px;
  right: 20px;
  line-height: 25px;
  border: 2px solid #bc9d4b;
  border-radius: 50%;
  position: fixed;
  text-align: center;
  font-size: 18px;
  z-index: 99;
}
.scroll-to-top.is-hidden {
  opacity: 0;
  -webkit-transform: translate(0, -5px);
  -webkit-transition: -webkit-transform 0.2s, background 0.2s, color 0.2s, opacity 0 0.2s;
}
.scroll-to-top.is-visible {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -webkit-transition: -webkit-transform .2s,background .2s,color .2s;
}

.scroll-to-top.style-2 {
  border-radius: 0;
}

.scroll-to-top:hover {
  background: #927937;
  color: white;
  border: 2px solid #927937;
}

.scroll-to-top:focus {
  background: #927937;
  color: white;
  border: 2px solid #927937;
}

/* End Scroll to Top */
/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a5d18;
  z-index: 999999;
}

.spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 45%;
  left: 48%;
}

.spinner-round:before {
  border-radius: 50%;
  content: " ";
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 3px rgba(255, 255, 255, 0.35);
  border-right: solid 3px rgba(255, 255, 255, 0.35);
  border-bottom: solid 3px rgba(255, 255, 255, 0.35);
  border-left: solid 3px rgba(255, 255, 255, 0.35);
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-round:after {
  border-radius: 50%;
  content: " ";
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 3px #bc9d4b;
  border-right: solid 3px transparent;
  border-bottom: solid 3px transparent;
  border-left: solid 3px transparent;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner-round-animate 1s ease-in-out infinite;
}

@keyframes spinner-round-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Preloader */
/* Media Screens */
@media only screen and (max-width: 1200px) {
  .pricing-currency {
    font-size: 14px;
    vertical-align: 20px;
  }

  .pricing-price {
    font-size: 40px;
  }

  .pricing-table .pricing-table-content ul li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .parallax-background {
    background-attachment: initial !important;
  }

  .split-menu .pull-left {
    float: none !important;
  }

  .split-menu .navbar-brand {
    display: block;
  }

  .split-menu .pull-right {
    float: none !important;
  }

  .split-menu .navbar-nav {
    float: none;
  }

  .split-menu .navbar-nav > li {
    float: none;
    display: inline-block;
  }

  .nav > li > a {
    padding: 12.5px 10px;
  }

  .open .dropdown-menu > li > a {
    font-size: 14px;
  }

  #image-header h2 {
    font-size: 30px;
  }

  .slide-title {
    font-size: 30px;
  }

  .opening-right .opening-title {
    margin-top: 30px;
  }

  .opening-left {
    border-right: none;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-brand {
    padding: 20px 15px 20px 0;
  }

  .navbar-toggle {
    padding: 25.5px 0 25.5px 5px;
  }

  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: rgba(255, 255, 255, 0.35);
    background: #1a5d18;
  }

  .navbar-default.top-nav-collapse .navbar-collapse, .navbar-default.top-nav-collapse .navbar-form {
    border-color: rgba(255, 255, 255, 0.35);
    background: white;
  }

  .open .dropdown-menu > li > a {
    padding: 5px 20px;
    text-align: center;
    max-width: 160px;
  }

  .navbar-nav .open .dropdown-menu {
    max-width: 160px;
    margin: 0 auto;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 15px;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: white;
    background-color: #bc9d4b;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: white;
    background-color: #bc9d4b;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #333333;
  }

  .navbar-nav > li > a {
    line-height: 40px;
    padding: 0 !important;
  }

  .navbar-nav {
    margin: 15px 0;
  }

  .navbar .btn {
    margin: 7px 0 20px 0;
  }

  header h3 {
    font-size: 30px;
  }

  header h5 {
    font-size: 16px;
  }

  header p {
    font-size: 14px;
  }

  #image-header {
    padding-top: 100px;
    padding-bottom: 20px;
  }

  #image-header img {
    max-width: 180px;
  }

  #image-header h2 {
    font-size: 30px;
  }

  .slide-title {
    font-size: 30px;
  }

  #slider.has-top-menu .slider-content {
    padding-top: 160px;
  }

  #slider img {
    max-width: 180px;
  }

  #slider .item {
    min-height: 580px;
  }

  #reservation-form {
    max-width: 90%;
  }

  .reservation-left-area {
    padding-right: 15px;
  }

  .reservation-right-area {
    padding-left: 15px;
  }

  #testimonials .testimonial h3 {
    font-size: 20px;
  }

  #contact-form {
    max-width: 90%;
  }

  .contact-left-area {
    padding-right: 15px;
  }

  .contact-right-area {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .col-xs-6.gallery-item {
    width: 100%;
  }

  .form-control {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 10px;
    min-height: 40px;
  }

  textarea {
    padding: 15px 10px !important;
  }

  .help-block {
    margin-top: 20px;
  }

  .help-block.with-errors li {
    margin-top: -45px;
  }

  .opening-box {
    width: 90%;
  }

  .customer-name {
    font-size: 14px;
  }

  .footer-info {
    text-align: center;
    line-height: 1.8;
  }

  .footer-info .pull-right {
    float: none !important;
    display: block;
  }
}
@media only screen and (max-width: 550px) {
  #slider .item {
    min-height: 580px;
  }
}
@media only screen and (max-width: 480px) {
  #slider .item {
    min-height: 560px;
  }

  header h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .header-brand {
    font-size: 26px;
  }

  header h5 {
    font-size: 12px;
    margin-bottom: 5px;
  }

  header p {
    padding: 10px 0;
  }

  .slide-title {
    font-size: 28px;
  }

  .opening-box {
    width: 100%;
  }

  #reservation-form {
    max-width: 100%;
  }

  #contact-form {
    max-width: 100%;
  }

  .spinner {
    left: 45%;
  }
}
/* Min Widths */
@media only screen and (min-width: 1300px) {
  .container {
    width: 1300px !important;
  }
}
@media only screen and (min-width: 1230px) {
  .container {
    width: 1230px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    width: 992px;
  }
}
@media only screen and (min-width: 992px) {
  .split-menu {
    max-height: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .navbar-nav {
    float: right;
  }

  .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .carousel-caption {
    right: 0;
    left: 0;
  }



}
/* End Min Widths */
/* / End Media Screens */

@media only screen and (max-width: 480px) {


  #slider .item {
    min-height: 292px;
  }

  .carousel-caption {
    right: 0;
    left: 0;
  }

}

@media only screen and (max-width: 767px) {
  #slider img {
    max-width: 100%;
  }

  #slider.has-top-menu .slider-content {
    padding-top: 81px;
  }


  section.incontent {
    padding-bottom: 0;
  }

  .page-header {
    margin-bottom: 0;
  }

  h1 {
    margin-bottom: 0;
  }

  .navbar-brand {
    padding: 5px 15px 0px 0;
  }

  .navbar-brand>img {
    width: 59px;
    max-height: inherit;
  }

}


.navbar-brand>img {
  position: absolute;
  width: 95px;
  max-height: inherit;
}

section.incontent {
  padding-top: 30px;
  padding-bottom: 30px;
}




.navbar-fixed-bottom, .navbar-fixed-top{
  background-color: #1a5d18;
}


